import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import UserImage from "../../images/web-user.jpg";
import { Link } from "react-router-dom";
import './Navbar2.css';
import LoginSignup from "./LoginSignup";
import { FiAlignLeft } from "react-icons/fi";
import { FaRegWindowClose } from "react-icons/fa";
import logoImg from "../images/examdiya logo.png";
import apiurl from "../apiUrl";

export const Navbar2 = () => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showSidebar, setShowSidebar] = useState(false);
    const [showExamDropdown, setShowExamDropdown] = useState(false);
    const [examSideDown, setExamSideDown] = useState(false);
    const [imageUrl, setImageUrl] = useState('');

    const userdownRef = useRef(null);
    const sidedownRef = useRef(null);
    const examDropdownRef = useRef(null);

    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const token = localStorage.getItem('token')
    // console.log(token)
    const userName = user.name || "User";
    const profiImag = user?.profileImage 
    ? `${apiurl}/user/image/${user.profileImage.replace(/\\/g, '/').replace("../", "")}`
    : UserImage;
    
    console.log(profiImag);
    
    const handleDialogOpen = () => setIsDialogOpen(true);
    const handleDialogClose = () => setIsDialogOpen(false);

   

    const fetchUserImg =()=>{
        const url = `${apiurl}/user/image/${user.profileImage}`;
        setImageUrl(url);
        console.log(url);
    }

    const handleLogin = () => {
        console.log("handleLogin called");
        setIsLoggedIn(true);
        handleDialogClose();
        fetchUserImg();
    };

    const handleUserClick = () => {
        if (isLoggedIn) {
            setShowDropdown((prev) => !prev);
        } else {
            handleDialogOpen();
        }
    };

    const handleClickOutside = (event) => {
        if (userdownRef.current && !userdownRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
        if (sidedownRef.current && !sidedownRef.current.contains(event.target)) {
            setShowSidebar(false);
        }
        if (examDropdownRef.current && !examDropdownRef.current.contains(event.target)) {
            setShowExamDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        // Auto-login if token exists
        const token = localStorage.getItem('token');
        if (token) {
           
            axios.get(`${apiurl}/user/auto-login`, {
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(response => {
                
                if (response.data.user) {
                    setIsLoggedIn(true);
                } else {
                    localStorage.removeItem('token');
                    localStorage.removeItem('user');
                    setIsDialogOpen(true); 
                }
            })
            .catch(() => {
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                setIsDialogOpen(true); // Show login dialog if token validation fails
            });
        }
    }, []);

  

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        setIsLoggedIn(false);
        setShowDropdown(false); // Close dropdown after logout
    };

    const menuList = [
        { label: userName, action: null },
        { label: "Phone Numb.", action: null },
        { label: "Setting", action: null },
        { label: "LogOut", action: handleLogout }
    ];

    return (
        <>
            <header className="headerNav">
                <div className="sidebar-toggle-icon" style={{ backgroundColor: showSidebar ? 'red' : 'green' }} onClick={() => setShowSidebar(!showSidebar)}>
                    {showSidebar ? <FaRegWindowClose style={{ color: 'white' }} /> : <FiAlignLeft style={{ color: 'white' }} />}
                </div>
                <div className="logo">
                    <Link to="/"><img className="logoimg" src={logoImg} />ExamDiya</Link>
                </div>
                <div className="menu-link-header">
                    <ul>
                        <li className="examOpt" style={{fontSize: "1.25rem"}} 
                        onClick={() => setShowExamDropdown(!showExamDropdown)}
                        >
                            Exam
                            {showExamDropdown && (
                                <div className="sub-head-examList" ref={examDropdownRef}>
                                    <ul className="subExamList">
                                        <li><Link to="/cbtexam">CBT Exam</Link></li>
                                        <li><Link to="/university">University Exam</Link></li>
                                    </ul>
                                </div>
                            )}
                        </li>
                        <li><a href="/currentaffair">Current Affair</a></li>
                        <li><a href="/quiz">Quiz</a></li>
                        
                        <li><a href="/jobalert">Job Alert</a></li>
                        <li><a href="#">Typing</a></li>
                        <li><a href="#">Notes</a></li>
                    </ul>
                </div>
                <div className="user" ref={userdownRef}>
                    <img 
                        src={profiImag} 
                        className="userLogo1" 
                        onClick={handleUserClick} 
                        alt="User Logo" 
                    />
                    {showDropdown && isLoggedIn && (
                        <div className="userList" onMouseLeave={() => setShowDropdown(false)}>
                            <ul>
                                {menuList.map((item, index) => (
                                    <li key={index} onClick={item.action || null}>
                                        {item.label}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            </header>

            {showSidebar && (
                <aside className="sidebar1" ref={sidedownRef}>
                    <div className="side-menu-link">
                        <ul>
                            <li onClick={() => setExamSideDown(!examSideDown)}>
                                Exam
                                {examSideDown && (
                                    <div className="sub-side-examList" >
                                        <ul onClick={()=> setShowSidebar(false)}>
                                            <li><Link to="/cbtexam">CBT Exam</Link></li>
                                            <li><Link to="/university">University Exam</Link></li>
                                        </ul>
                                    </div>
                                )}
                            </li>
                            <li><a href="/currentaffair">Current Affair</a></li>
                            <li><a href="/quiz">Quiz</a></li>
                            <li><a href="/jobalert">Job Alert</a></li>
                            <li><a href="#">Typing</a></li>
                            <li><a href="#">Notes</a></li>
                        </ul>
                    </div>
                </aside>
            )}

            <LoginSignup open={isDialogOpen} onClose={handleDialogClose} onLogin={handleLogin} />
        </>
    );
};

export default Navbar2;
