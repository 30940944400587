import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./unversityS2.css";
import axios from 'axios';
import apiurl from '../apiUrl';

const bachelorList = {
  "B.A": ["B. A. Prog.", "English", "Hindi", "History", "Geography", "Political Science", "Economics"],
  "B.Sc": ["B.Sc Prog.", "Chemistry", "Biochemistry", "Mathematics", "Biomedical Science", "Zoology", "Botany"],
  "B.Com": ["B.Com Programme"],
};
const semesterItems = {
  "B.A": ["1st", "2nd", "3rd", "4th", "5th", "6th", "All"],
  "B.Sc": ["1st", "2nd", "3rd", "4th", "5th", "6th", "All"],
  "B.Com": ["1st", "2nd", "3rd", "4th", "5th", "6th", "All"],
  "M.Com":  ["1st", "2nd", "3rd", "4th", "All"],
};

const masterList = {
  "M.A": ["English", "Hindi", "History", "Political Science", "Sociology", "Philosophy"],
  "M.Sc": ["Physics", "Chemistry", "Mathematics", "Zoology", "Botany", "Computer Science"],
  "M.Com": ["Accounting", "Finance", "Marketing", "Business Studies"],
};


const University = () => {
  const { university } = useParams();
  const [selectedHeader, setSelectedHeader] = useState("Previous Exam");
  const [program, setProgram] = useState("");
  
  const [examName, setExamName] = useState('');
  const [courseType, setCourseType] = useState('');
  const [subject, setSubject] = useState("");
  const [semester, setSemester] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [data, setData] = useState([]);
  const [isFormVisible, setIsFormVisible] = useState(true);

  const handleHeaderClick = (section) => {
    setSelectedHeader(section);
    getData(section);
  }

  

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
    setSemester("");
  };

  const handleSemesterChange = (e) => setSemester(e.target.value);

  const getExamNameOpt = () => {
    if (program === "Bachelor") return Object.keys(bachelorList);
    if (program === "Master") return Object.keys(masterList);
    return [];
  };

  const getCourseTypes = () => {
    console.log(examName);
    if (program === "Bachelor") return bachelorList[examName] || [];
    if (program === "Master") return masterList[examName] || [];
    return [];
  };

  const getSemester = () => semesterItems[examName] || [];

  const getData = async (section) => {
    setLoading(true);
    setErrorMessage('');
    try {
      const todaydate = new Date().getFullYear(); // Current date
      const oneYearAgo = new Date().getFullYear() -1;

      const response = await axios.get(`${apiurl}/uni/getdata`, {
        params: {
          university,
          category: section,
          examName, courseType, 
          todaydate, olddate :oneYearAgo,
          semester,
        },
      });
console.log(response.data.files);
      if (response.data.files) {
        setData(response.data.files);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response && error.response.status === 404) {
        setErrorMessage("No data found");
      } else {
        setErrorMessage("An error occurred while fetching data");
      }
      setData([]);
    } finally {
      setLoading(false);
    }

  };

  useEffect(() => {
    getData(selectedHeader); // Run on page load
  }, []);

  const handleSearch = async () => {
    setIsFormVisible(false); 
    setLoading(true);
    setErrorMessage('');
    try {
      const response = await axios.get(`${apiurl}/uni/searchdata`, {
        params: {
          university,
          category: selectedHeader,
          examName, courseType,
          semester,
        },
      });

      if (response.data.files) {
        const sortedData = await response.data.files.sort((a, b) => parseInt(b.year) - parseInt(a.year));
        setData(sortedData);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response && error.response.status === 404) {
        setErrorMessage("No data found");
      } else {
        setErrorMessage("An error occurred while fetching data");
      }
      setData([]);
    } finally {
      setLoading(false);
    }

  };

  const handleDownload = async (filename) => {
    try {
      console.log(`Downloading: ${filename}`);
      const response = await fetch(`${apiurl}/uni/download/${filename}`, {
        method: "GET",
      });
  
      if (!response.ok) {
        throw new Error(`Failed to download file: ${response.statusText}`);
      }
  
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
  
      // Create a temporary link to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
  
      // Clean up resources
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
  
      console.log(`${filename} downloaded successfully!`);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleSearchToggle = () => {
    setIsFormVisible(!isFormVisible); // Toggle form visibility
  };

  return (
    <div className="university-container">
      <div className="header-search">
        <div className="uni-name" onClick={()=>getData("Previous Exam")}>{university} Exams  </div>
              <header className="univ-header">
                
                
                {["Previous Exam", "Guess Paper", "Entrance Exam", "Practical/Asses.", "Study Material"].map((section) => (
                  <div
                  key={section}
                  className={`univ-header-section ${selectedHeader === section ? "selected" : ""}`}
                  onClick={() => handleHeaderClick(section)}
                  >
                    {section}
                  </div>
                ))}

              </header>

              <header className="res-univ-header">
              <select  className="univ-header-dropdown selecttag"  value={selectedHeader}
                onChange={(e) => handleHeaderClick(e.target.value)}
              >
                <option value="" className="res-uni-header-opt">Select Degree</option>
                {["Previous Exam", "Guess Paper", "Entrance Exam", "Practical/Asses.", "Study Material"].map((section) => (
                  <option key={section} value={section}>
                    {section}
                  </option>
                ))}
              </select>
            </header>

             

              <button className="toggle-search-button" onClick={handleSearchToggle}>
                {isFormVisible ? 'Hide Search Form' : 'Search'}
              </button>
        </div>

      {isFormVisible &&
       <div className="dropdown-row">
        <div className="dropdown-container">
          <label>Program:</label>
          <select className="selecttag" value={program} onChange={(e)=>{setProgram(e.target.value);}}>
            <option value="">Select Program</option>
            <option value="Bachelor">Bachelor</option>
            <option value="Master">Master</option>
          </select>
        </div>

        <div className="dropdown-container">
          <label>Exam Name:</label>
          <select className="selecttag" value={examName} onChange={(e)=>{setExamName(e.target.value)}} disabled={!program}>
            <option value="">Select Exam Name</option>
            {getExamNameOpt().map((prog) => (
              <option key={prog} value={prog}>
                {prog}
              </option>
            ))}
          </select>
        </div>

        <div className="dropdown-container">
          <label>Course Type:</label>
          <select className="selecttag" value={courseType} onChange={(e)=>{setCourseType(e.target.value)}} disabled={!examName}>
            <option value="">Select Course Type</option>
            {getCourseTypes().map((subj) => (
              <option key={subj} value={subj}>
                {subj}
              </option>
            ))}
          </select>
        </div>

        <div className="dropdown-container">
          <label>Semester:</label>
          <select className="selecttag" value={semester} onChange={handleSemesterChange} disabled={!courseType}>
            <option value="">Select Semester</option>
            {getSemester().map((sem) => (
              <option key={sem} value={sem}>
                {sem}
              </option>
            ))}
          </select>
        </div>

        <button onClick={handleSearch} disabled={!semester}>Search</button>
      </div>
      }

      {data && (
         <div className="uni-selection-summary">
         {data.map((item, index) => (
           <div key={index} className="uni-card">
             {/* Top Row: Heading for Section */}
             <div className="uni-card-heading">
               {item.category} Paper
             </div>
             {/* Second Row: Program Name + Subject */}
             <div className="uni-card-subheading">
               {item.examName} {item.courseType} {item.semester} Sem. ({item.year})
             </div>

             {/* Top Row: Heading for Section */}
             <div className="uni-card-heading">
               {item.section}
             </div>
   
   
             {/* Third Row: Subject Name */}
             <div className="uni-card-body">
               Subject Name:- {item.subjectName}
             </div>
   
             {/* Download Button */}
             <div className="uni-card-footer">
               <button className="uni-download-button" onClick={()=>{handleDownload(item.fileName)}}>Download</button>
             </div>
           </div>
         ))}
       </div>
     )}
    </div>
  );
};

export default University;
